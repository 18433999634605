.MenuGrom .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .MenuGrom .menu_text {
    color: #fff;
  }
  
  .MenuGrom .logo_menu_mobile {
    height: 40px;
    margin: 5px;
  }
  
  .MenuGrom .logo_menu_desktop {
    height: 80px;
    margin: 10px;
  }
  
  .MenuGrom .cup_mobile {
    height: 30px;
    margin: 5px;
  }
  
  .MenuGrom .cup_desktop {
    height: 70px;
    margin: 10px;
  }
  