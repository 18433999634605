@media (min-width: 600px) {
    .logo_header {
      height: 40px;
      margin: 10px;
      transition: transform 0.2s;
    }
    
    .logo_header:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
    
    .menu-item {
      margin-left: 25px;
      position: relative;
      padding-left: 25px;
      padding-right: 0px;
      color: #242F37; 
    }
    
    .menu-item-text {
      color: #ccc;
      font-size: 16px; /* Устанавливаем размер шрифта для десктопной версии */
    }
    
    .menu-item.hovered {
      color: #ff0000;  /* Измените это на цвет, который вы хотите использовать */
    }
    
    .menu-item::before {
      content: "";
      position: absolute;
      height: 60%;
      border-left: 1px solid grey;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
}
  
@media (max-width: 599px) {
    .menu-item {
      position: relative;
      color: #242F37; 
    }
    
    .menu-item-text {
      color: #ccc;
    }
    
    .logo_header {
      padding-left: 5px;
      height: 30px; /* Уменьшаем размер логотипа для мобильной версии */
      margin: 0 2px;
    }

}
