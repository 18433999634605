  .HomePage {
    color: #242F37;
    color: #FFFFFF;
  }

  .HomePage .MuiTypography-h2 {
    font-weight: 700;
  }

  .team-photo-wrapper {
    position: relative;
  }
  
  .team-photo {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .team-logo {
    position: absolute;
    max-width: 30%;
    opacity: 0.8;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .react-player {
    margin-bottom: 20px;
  }
  
  .home-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .link-text {
    color: inherit;
    text-decoration: none;
  }
  
  .link-text:hover {
    color: grey; /* или любой другой цвет, который вам нравится */
  }