.pagination {
  text-align: right;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-table-content td {
  text-align: center;
}

.center-table-headers th {
  text-align: center;
}